// Colors
// --------------------------------------------------
$white:         #ffffff;
$black:         #000000;
$primary-text:  #777777;
$separator:     #EBEBEB;
$light-gray:    #D7D7D7;
$lighter-gray:  #CCCCCC;
$chart:         $lighter-gray;

// Fonts
// -----------
$sans:          'Roboto', Arial, Helvetica, sans-serif;
$codeFont:      'Roboto Mono', monospace;
