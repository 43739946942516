.highlight code,
.highlight pre {color:#272822;background-color:#E5E5E5}
.highlight .hll{background-color:#222}
.highlight .c{color:#7f9f7f}
.highlight .err{color:#e37170;background-color:#3d3535}
.highlight .g{color:#7f9f7f}
.highlight .k{color:#C7285E}
.highlight .l{color:#ccc}
.highlight .n{color:#272822}
.highlight .o{color:#272822}
.highlight .x{color:#ccc}
.highlight .p{color:#272822}
.highlight .cm{color:#7f9f7f}
.highlight .cp{color:#7f9f7f}
.highlight .c1{color:#8C8C8C}
.highlight .cs{color:#cd0000;font-weight:bold}
.highlight .gd{color:#cd0000}
.highlight .ge{color:#ccc;font-style:italic}
.highlight .gr{color:red}
.highlight .gh{color:#dcdccc;font-weight:bold}
.highlight .gi{color:#00cd00}
.highlight .go{color:gray}
.highlight .gp{color:#dcdccc;font-weight:bold}
.highlight .gs{color:#ccc;font-weight:bold}
.highlight .gu{color:purple;font-weight:bold}
.highlight .gt{color:#0040D0}
.highlight .kc{color:#dca3a3}
.highlight .kd{color:#ffff86}
.highlight .kn{color:#dfaf8f;font-weight:bold}
.highlight .kp{color:#7644E4}
.highlight .kr{color:#cdcd00}
.highlight .kt{color:#00cd00}
.highlight .ld{color:#cc9393}
.highlight .m{color:#8cd0d3}
.highlight .s{color:#cc9393}
.highlight .na{color:#9ac39f}
.highlight .nb{color:#5F868F}
.highlight .nc{color:#272822}
.highlight .no{color:#5F868F}
.highlight .nd{color:#ccc}
.highlight .ni{color:#c28182}
.highlight .ne{color:#c3bf9f;font-weight:bold}
.highlight .nf{color:#41822D}
.highlight .nl{color:#888}
.highlight .nn{color:#8fbede}
.highlight .nx{color:#ccc}
.highlight .py{color:#ccc}
.highlight .nt{color:#9ac39f}
.highlight .nv{color:#7644E4}
.highlight .ow{color:#f0efd0}
.highlight .w{color:#ccc}
.highlight .mf{color:#8cd0d3}
.highlight .mh{color:#7644E4}
.highlight .mi{color:#7644E4}
.highlight .mo{color:#8cd0d3}
.highlight .sb{color:#cc9393}
.highlight .sc{color:#cc9393}
.highlight .sd{color:#cc9393}
.highlight .s2{color:#728B14}
.highlight .se{color:#cc9393}
.highlight .sh{color:#cc9393}
.highlight .si{color:#728B14}
.highlight .sx{color:#cc9393}
.highlight .sr{color:#728B14}
.highlight .s1{color:#728B14}
.highlight .ss{color:#7644E4}
.highlight .bp{color:#efef8f}
.highlight .vc{color:#272822}
.highlight .vg{color:#272822}
.highlight .vi{color:#272822}
.highlight .il{color:#8cd0d3}

.highlight .lineno {
  border-right: 1px solid #8C8C8C;
  color: #8C8C8C;
  padding-right: 15px;
  width: 17px;
  display: inline-block;
  text-align: right;
}
