// Sprites
// --------------------------------------------------

i.extra-icons {
  background: url("/assets/icons.svg") no-repeat 40px 40px;
  background-size: 120px 120px;
  display: inline-block;
  vertical-align: middle;
  width: 26px;
  height: 23px;
  line-height: 20px;

  &.github          { background-position: 0px -24px; }
  &.stack_overflow  { background-position: 1px -1px; }
  &.gitter          { background-position: -24px -24px; }
  &.libera_chat     { background-position: -48px -72px; }
  &.discord         { background-position: 0px -72px; }
  &.google          { background-position: -47px -24px; }
  &.twitter         { background-position: -24px -1px; }
  &.reddit          { background-position: -72px -1px; }
  &.awesome         { background-position: -72px -47px; }
  &.google_fonts    { background-position: -72px -25px; }
  &.google_plus     { background-position: -24px -47px; }
  &.facebook        { background-position: 1px -48px; }
  &.telegram        { background-position: -24px -72px; }
}

i.extra-icons.gray {
  opacity: .55;
}

i.manas {
  background: url("/assets/m.svg");
  width: 30px;
  height: 30px;
  display: inline-block;
  vertical-align: middle;
  line-height: 30px;
}

.material-icons {
  vertical-align: bottom;
}
