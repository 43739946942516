div.highlighter-rouge, figure.highlight {
  position: relative;
  margin: 0;
  border: 1px solid $light-gray;
  padding: 1.5em 2em;
  margin-top: 2em;

  & + & {
    border-top: none;
    margin-top: 0;
  }

  pre {
    margin: 0;
  }
  code, pre {
    background-color: transparent;
  }

  // This is a hack. Since I can't have 4 shapes in the code_section
  // I'm setting the lower two squares to be for the element inside
  &, > :first-child {
    &:before, &:after {
      content: "";
      border: solid 1px $light-gray;
      width: 5px;
      height: 5px;
      position: absolute;
      background-color: $white;
    }
  }

  &:before {
    top: -3px;
    left: -3px;
  }
  &:after {
    top: -3px;
    right: -3px;
  }
  > :first-child {
    &:before {
      bottom: -3px;
      left: -3px;
    }
    &:after {
      bottom: -3px;
      right: -3px;
    }
  }
}

code.highlighter-rouge {
  background-color: #E5E5E5;
  padding: 0 5px;
}
