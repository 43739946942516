html, .wrapper {
  height: 100%;
}
body {
  height: 100%;
  margin: 0px;
}

.row p {
  margin: 30px 0;
}

.wrapper {

  .brand-logo {
    position: relative;
    color: #fff;
    display: inline-block;
    font-size: 2.1rem;
    padding: 0;
    white-space: nowrap;
  }



  #logo-container {
    background-color: $white;
    border-radius: 50%;
    margin: 15px;
    height: 66px;
    width: 66px;
    padding: 3px;
    border-bottom: 0;

    #logo-canvas {
      width: 60px;
      height: 60px;
    }
  }
  nav {
    height: 96px;
    background-color: $white;
    box-shadow: none;
    border-bottom: 1px solid $separator;


    ul#nav-desktop li a {
      height: 96px;
      line-height: 96px;
      color: $black;
      padding: 0;
      margin: 0 15px;
      border-bottom: 0;

      &.selected {
        border-bottom: 5px solid $black;
      }
      &:hover {
        background-color: transparent;
        border-bottom: 5px solid $black;
      }
    }

    a.button-collapse {
      color: $black;
      float: right;
      i {
        font-size: 2.5em;
        line-height: 96px;
      }
    }
  }
}


hr.squares {
  border-bottom: 1px solid $light-gray;
  border-top: 0;
  position: relative;
  margin: 25px 0;

  &:before, &:after {
    content: "";
    border: solid 1px $light-gray;
    width: 3px;
    height: 3px;
    position: absolute;
  }
  &:before {
    top: -2px;
    left: -6px;
  }
  &:after {
    top: -2px;
    right: -6px;
  }
}


.header-section {
  min-height: 465px;
  width: 100%;

  // This padding makes room for .banner.sponsorship to overlap into .header-section
  padding-bottom: 30px;
  @media only screen and (max-width: 768px) {
    min-height: 265px;
    // On small width, .banner.sponsorship spans over two lines, needing more space
    padding-bottom: 45px;
  }

  .title {
    color: $black;
    font-size: 72px;
    margin: 30px auto;
  }
  .subtitle {
    font-size: 18px;
  }
  #content {
    display: block;
    width: 90%;
    margin: auto;
  }
  @media only screen and (min-width: 601px) {
    #content {
      width: 85%;
    }
  }

  @media only screen and (min-width: 993px) {
    #content {
      width: 70%;
    }
  }
}

.side-nav#nav-mobile {
  text-align: left !important;
  a {
    font-weight: 400;
    font-size: 20px;
    height: 60px;
  }
}

@media only screen and (min-width: 993px) {
  .main .community-list .item {
    white-space: nowrap;
  }
}

@media only screen and (max-width: 992px) {
  nav .brand-logo {
    left: 10%;
  }
  .main .community-list a.item {
    display: block;
    text-align: center;
  }
  footer {
    text-align: center !important;
    height: 100px !important;
    .right-align {
      text-align: inherit;
    }
  }
}

@media only screen and (max-width: 600px) {
  .wrapper {
    .header-section {
      .title {
        font-size: 2.8rem;
      }
    }
    nav {
      a.button-collapse i {
        font-size: 1.8rem;
        line-height: 72px;
      }
    }
    &:not(.main) {
      #logo-container {
        margin: 5px 0 0 5px;
        height: 75px;
        width: 75px;

        #logo-canvas {
          width: 75px;
          height: 75px;
        }
      }
    }
    .become-sponsor,
    .faq h2 {
      text-align: center;
    }
  }
}
