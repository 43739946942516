#sponsors-tbl {
  border-color: $light-gray;
  th {
    color: $black;
  }
  .currency, .since {
    text-align: right;
  }
  .since {
    padding-left: 25px;
  }

  .sponsor {
    .logo {
      img {
        border-radius: 50%;
        width: 40px;
        height: 40px;
        object-fit: contain;
      }
    }
    .legend {
      font-size: 13px;
      color: #b5b5b5;
      text-transform: uppercase;
    }
    &:not(:hover) .logo img {
      filter: grayscale(100%);
      opacity: .6;
      transition: all .25s ease;
    }
  }
  td:first-child, th:first-child {
    position: relative;
    &:before {
      content: "";
      border: solid 1px $light-gray;
      width: 5px;
      height: 5px;
      position: absolute;
      bottom: -3px;
      left: -4px;
    }
  }
  td:last-child, th:last-child {
    position: relative;
    &:after {
      content: "";
      border: solid 1px $light-gray;
      width: 5px;
      height: 5px;
      position: absolute;
      bottom: -3px;
      right: -4px;
    }
  }

  th[role=columnheader]:not(.no-sort) {
    cursor: pointer;
  }

  th[role=columnheader]:not(.no-sort) > span {
    display: inline-flex;
    align-items: center;
  }

  th[role=columnheader]:not(.no-sort) > span:after {
    display: inline-block;
    content: '';
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z" fill="%23000"></path></svg>');
    visibility: hidden;
    opacity: 0;
    -ms-user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    width: 1em;
    height: 1em;
    margin-left: 4px;
  }

  th.currency, th.since {
    &:not(.no-sort) > span:after {
      order: -1;
      margin-left: 0;
      margin-right: 4px;
    }
  }

  th[aria-sort=ascending]:not(.no-sort) > span:after {
    transform: rotateZ(180deg);
  }

  th[aria-sort]:not(.no-sort) > span:after {
    visibility: visible;
    opacity: 0.4;
  }

  th[role=columnheader]:not(.no-sort):hover > span:after {
    visibility: visible;
    opacity: 1;
  }
}

.sponsors-sidebar {
  h3 {
    line-height: 160%;
  }
}
