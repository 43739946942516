.main {
  // Changes to the default layout for main page
  .header-section {
    background-color: transparent;
    min-height: 485px;
    position: relative;
    h1.title {
      color: $white;
    }
    .subtitle {
      color: $white;
      font-size: 32px;
      margin-bottom: 40px;
    }
  }

  p.main-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media only screen and (min-width: 601px) {
      flex-direction: row;
    }

    .btn {
      margin: 8px;
    }
  }

  nav {
    background-color: transparent;
    border: none;
    ul#nav-desktop {
      li a {
        color: $white;
      }
    }
    a.button-collapse {
      color: $white;
    }
  }

  &:not(.conference) nav {
    background-color: transparent;
    border-bottom: 0px;
    position: relative;
    text-align: center;
    z-index: 1000;


    ul#nav-desktop {
      display: inline-block;
      float: none !important;
      margin: 0 auto !important;
      li a {
        color: $white;
        &:hover {
          border-color: $white;
        }
        &.selected {
          border-bottom: 5px solid $white;
        }
      }
    }
  }
  #crystal-container {
    text-align: center;
    margin-bottom: 15px;
    #Crystal {
      fill: #ffffff;
    }
  }
  &:not(.conference) #logo-container {
    width: 160px;
    height: 160px;
    #logo-canvas {
      width: 150px;
      height: 150px;
    }
  }

  #delaunay {
    @extend .black;
    position: absolute;
    top: -96px;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  canvas.delaunay {
    position: absolute;
    background-color: $black;
    top: 0;
    bottom: 15px;
  }

  .container {
    // Removed container width for the banners,
    // so this is making up for it.
    width: 70%;
    max-width: 1280px;
    margin: 0 auto;
  }

  @media only screen and (max-width: 992px) {
    // The 70% fix for container should be undone in mobile.
    .container {
      width: 90%;
    }
  }

  p.read-more > a {
    word-break: break-all;
  }

  .sponsors-list {
    text-align: center;
    // border-bottom: 1px solid $light-gray;
  }
  .sponsor {
    display: inline-block;
    margin: 40px 45px;
    text-align: center;
    position: relative;
    img {
      width: 96px;
      height: 96px;
      display: block;
      filter: grayscale(100%);
      border-radius: 50%;
      margin: 20px auto 50px auto;
    }
    &:hover {
      img {
        filter: grayscale(0%);
      }
    }
  }

  .community-list {
    text-align: center;

    .item {
      display: inline-block;
      margin: 45px 0 0 0;
      text-align: left;
      span.small {
        display: block;
        margin: 5px 0;
      }
      i, span.with_icon {
        color: $black;
      }
    }
  }

  &:not(.conference) h2 {
    margin: 100px 0 30px 0;
  }
  hr.fullwidth {
    background: $light-gray;
    position: absolute;
    left: 0;
    width: 100%;
    border: none;
    height: 1px;
  }

  .flag {
    background-color: $white;
    line-height: 30px;
    border-radius: 35px;
    border: 1px solid $light-gray;
    display: inline-block;
    padding: 2px 25px;
    white-space: nowrap;
    z-index: 1;
    transform: translateY(-50%);
    h3 {
      display: inline-block;
      background-color: $white;
      margin-top: 0;
    }
    a {
      border-bottom: 1px solid $primary-text;
      margin-left: 8px;
    }
  }


  .banner.sponsorship {
    margin-top: 0;
    border-top: 1px solid $light-gray;

    .flag-logos {
      display: inline;
      span {
        margin: 0 3px;
      }
    }
    .sponsorship-logo{
      height: 28px;
    }
    .oc-logo {
      height: 18px;
    }
    .flag {
      a { border-bottom: 0; }
    }
    @media only screen and (max-width: 768px) {
      .flag-logos {
        display: block;
        text-align: center;
      }
    }
  }

  .btn-flat {
    border: 2px solid $white;
    color: $white;
    padding: 0 42px;
    &:active {
      background-color: $black;
    }
    &:hover {
      color: $black;
      background-color: $white;
    }
  }

  .latest-release {
    font-family: "Roboto Mono", monospace;
    text-transform: inherit;
    margin-top: 70px;
    font-size: 48px;
    color: #999999;
    a { color: #000000; }
  }
}

.contributing-link {
  color: #03A9F4;
  border-bottom: 0;
  &:hover {
    color: #03A9F4;
    border-bottom: 1px solid #03A9F4;
  }
}

@media only screen and (max-width: 992px) {
  .btn-flat {
    display: block;
    width: 80%;
    margin-right: 0;
    margin: 0 auto 1rem !important;
  }
  .btn-large {
    font-size: 18px;
  }
  .main .banner.sponsorship {
    margin-top: 0px;
    .flag {
      line-height: 1.3;
      border-radius: 0px;
      padding: 6px 25px;
      white-space: normal;
      display: block;
      &:before {
        display: none;
      }
      .flag-logos {
        line-height: 1.6;
      }
    }
  }
}

@media only screen and (min-width: 841px) {
  .register-mobile {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .main:not(.conference) {
    nav {
      a.brand-logo {
        position: absolute;
        left: calc(50% - 15px);
        top: 2rem;
      }
    }
    .header-section.valign-wrapper {
      display: block;
    }
    #crystal-container {
      margin-bottom: 5px;
      #Crystal {
        width: 150px !important;
        height: auto !important;
      }
    }
    #logo-container {
      width: 110px;
      height: 110px;
      #logo-canvas {
        width: 100px;
        height: 100px;
      }
    }
  }
}

.top-banner {
  background: #828282;

  a {
    color: $white;

    line-height: 60px;
    padding: 0;
    height: 120px;

    display: flex;
    flex-flow: column;
    flex-wrap: nowrap;
  }

  .tb-register {
    background: #9d9d9d;
    font-family: "Roboto Mono", monospace;
    font-size: 1.3rem;
    font-style: italic;
    padding: 0 1rem;
    text-align: center;
  }

  .tb-title {
    font-size: 1.15rem;
    padding: 0 0.5em;
    text-align: center;
  }

  .tb-thin {
    font-weight: 200;
  }

  .tb-gap {
    flex-grow: 1;
  }

  .tb-date {
    font-size: .9rem;
    padding: 0 0.5em;
    display: none;
  }
}

@media only screen and (min-width: 841px) {
  .top-banner {
    a {
      flex-flow: row;
      height: 60px;
    }

    .tb-title {
      text-align: left;
    }

    .tb-date {
      display: flex;
    }
  }
}
