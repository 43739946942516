.media-gallery {
  .logo {
    position: relative;
    display: inline-block;
    border: 1px solid $light-gray;
    margin: 30px 24px 0 0;
    width: 180px;
    height: 180px;
    text-align: center;

    .border {
      img {
        object-fit: scale-down;
        height: 140px;
        display: block;
        margin: 5px auto;
      }

      &:before, &:after {
        content: "";
        border: solid 1px $light-gray;
        width: 5px;
        height: 5px;
        position: absolute;
        background-color: $white;
      }
      &:before {
        top: -3px;
        left: -3px;
      }
      &:after {
        top: -3px;
        right: -3px;
      }
    }

    &:before, &:after {
      content: "";
      border: solid 1px $light-gray;
      width: 5px;
      height: 5px;
      position: absolute;
      background-color: $white;
    }
    &:before {
      bottom: -3px;
      left: -3px;
    }
    &:after {
      bottom: -3px;
      right: -3px;
    }
  }
}

.color {
  text-align: center;

  .color-item {
    display: inline-block;
    margin: 20px 0 0 0;
  }
  .color-sample {
    border-radius: 50%;
    width: 72px;
    height: 72px;
    border: 2px solid $black;
    margin: 10px auto;
    &.black {
      background-color: $black;
    }
    &.white {
      background-color: $white;
      border-color: $primary-text;
    }
    &.gray {
      background-color: $primary-text;
      border-color: $primary-text;
    }
  }
  .color-name {
    display: block;
  }
}

.fonts {
  .font-item {
    margin-top: 15px;

    .font-name {
      display: block;
    }
    .font-sample {
      display: block;
      color: $black;
      font-size: 22px;
      word-wrap: break-word;
      &.roboto {
        font-family: 'Roboto';
      }
      &.medium {
        font-family: 'Roboto';
        font-weight: 500;
      }
      &.mono {
        font-family: 'Roboto Mono';
      }
    }
  }
}

.media-guidelines {
  ul.guidelines.general {
    line-height: 1.8;
    li {
      list-style-type: square;
      list-style-position: outside;
      padding-left: 0.5em;
      margin-left: 2em;
    }
  }

  ul.guidelines.dos, ul.guidelines.donts {
    list-style: none;
    padding: 0;
    li {
      padding-left: 2.5em;
    }
    li:before {
      font-family: 'Material Icons';
      display: inline-block;
      margin-left: -1.8em; /* same as padding-left set on li */
      width: 1.8em; /* same as padding-left set on li */
      top: 3px;
      font-size: 18px;
      position: relative;
    }
  }
  ul.dos li:before {
    content: "check";
  }
  ul.donts li:before {
    content: "clear";
  }
}
