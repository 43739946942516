// Error Pages
// --------------------------------------------------

.wrapper.error {
  .header-section {
    display: none;
  }
  main {
    min-height: calc(100vh - 180px);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    .header-error {
      h1 {
        @extend .black-text;
        font-size: 4.5rem;
      }
      p {
        font-size: 1.2rem;
        &.subtitle {
          font-family: "Roboto Mono", monospace;
          font-size: 2rem;
          margin: 3rem auto;
          .tblue {
            color: #4f72b7;
          }
          .tgreen {
            color: #4fa14b;
          }
        }
      }
    }
  }
  footer {
    margin: 0;
  }
}