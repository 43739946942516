.highlight {
  span.nb {
    color: #E45546;
  }
  span.s2, span.si {
    color: #51A24D;
  }
  span.nf {
    color: #4F72B7;
  }
  span.k {
    color: #9F3D96;
  }
  span.no {
    color: #000000;
  }
  span.mi {
    color: #976B29;
  }

  &.console {
    span.type {
      color: goldenrod;
    }
    span.marker {
      color: darkseagreen;
    }
  }

  code.language-javascript {
    span.nx, span.err {
      color: #E45546;
      background-color: white;
    }
    span.kr {
      color: #4F72B7;
    }
    span.mi {
      color: #9F3D96;
    }
    span.kd, span.k {
      color: #51A24D;
    }
    span.s2 {
      color: #4D70B6;
    }
  }
}
